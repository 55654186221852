/*
// #Widget - Contact Info
// --------------------------------------------------
*/

.widget-contact-info {

}
  .widget-contact-info__desc {
    margin-bottom: 32px;
  }

  .widget-contact-info__body {

  }
