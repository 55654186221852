// Alerts

@mixin alert-variant($background, $border, $icon-bg) {
	background-color: $background;
	border-color: $border;

	hr {
		border-top-color: darken($border, 5%);
	}
	.alert-link {
		// color: darken($text-color, 10%);
	}

	&::before {
		background-color: $icon-bg;
	}
}
