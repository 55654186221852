
/* Event: Table Results */
.alc-event-results-table {

}
.alc-event-results-table--fullwidth {

	.alc-event-results-cell__player {
		width: 1px;
		white-space: nowrap;

		@media (min-width: 1200px) {
			padding-right: 50px !important;
		}
	}
}
