/* Video Playlist */
.alc-video-player-col {
	padding-right: 0;
}
.alc-video-playlist-col {

	@media (min-width: 992px) {
		border-left: 1px solid $card-border-color;
		padding-left: 0;
	}
}

// Title
.alc-video-player__header {
	border-bottom: 1px solid $card-border-color;
	padding-left: $card-horizontal-padding;
	padding-right: $card-horizontal-padding;
	padding-top: 13px;
	padding-bottom: 13px;
	position: relative;

	@media (max-width: 991px) {
		border-top: 1px solid $card-border-color;
	}

	.slick-prev,
	.slick-next {
		top: 50%;
		transform: translateY(-50%);
	}
}
	.alc-video-player__title {
		font-size: 11px;
		line-height: 1.2em;
		margin-bottom: 0;
	}

// Video Player
.alc-video-player {

	.posts__thumb--video {
		float: none;
		margin: 0;

		img {
			width: 100%;
		}
	}
}

// Playlist
.alc-video-player__video-list {
	position: relative;

	.posts__item {
		margin-left: 0 !important;
		margin-right: 0 !important;
		margin-bottom: 0 !important;
		padding-top: 18px;
		padding-bottom: 18px;

		&:hover {
			cursor: pointer;
		}

		.posts__title {

			@media (min-width: 992px) and (max-width: 1199px) {
				font-size: 12px;
			}
		}
	}
}

// Embeded Player
.alc-embeded-player {
	position: relative;
	overflow: hidden;
	background-color: #000;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%;
	border: 1px solid $card-border-color;

	.card--no-paddings > .card__content & {
		border-radius: $card-border-radius;
		border: none;
	}

	.card--no-paddings > .card__header + .card__content & {
		border-bottom-left-radius: $card-border-radius;
		border-bottom-right-radius: $card-border-radius;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border: none;
	}


	// remove overlay if duotone enabled
	&.effect-duotone {

		&:hover {
			cursor: pointer;
		}

		.alc-embeded-player__overlay {

			&,
			&:hover {
				background-color: transparent !important;
			}
		}
	}
}

.alc-embeded-player--bg-color-dark {
	background-color: $color-dark;

	&:hover {

		.alc-embeded-player__overlay {
			background-color: rgba($color-dark, .5);
		}
	}
}
	.alc-embeded-player__overlay {
		display: flex;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0,0,0,.8);
		justify-content: center;
		align-items: center;

		.alc-embeded-player--bg-color-dark & {
			background-color: rgba($color-dark, .75);
			transition: background-color .3s ease;
		}

		&:hover {
			cursor: pointer;

			.alc-embeded-player__icon {
				opacity: .5;
			}
		}
	}
		.alc-embeded-player__icon {
			display: block;
			color: $color-white;
			font-size: 30px;
			line-height: 1em;
			transition: opacity .3s ease;
		}


		.alc-embeded-player__inner {
			position: absolute;
			left: 0;
			bottom: 0;
			padding: 0 $card-horizontal-padding 18px $card-horizontal-padding * 2;
			pointer-events: none;
			z-index: 4;

			@media (min-width: 992px) {
				padding-left: 90px;
				padding-bottom: 34px;
				width: 85%;
			}

			&::before {
				content:"\f04b";
				display: block;
				position: absolute;
				left: 10px;
				top: 0;
				width: 30px;
				height: 30px;
				background-color: rgba(23, 29, 33, .6);
				border-radius: 50%;
				border-width: 2px;
				border-style: solid;
				z-index: 4;
				color: #fff;
				font-size: 12px;
				text-align: center;
				line-height: 26px;
				text-indent: 3px;
				font-family: 'Font Awesome 5 Free';
				font-weight: 700;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				transition: transform 0.3s ease;
				pointer-events: none;

				@if $template == 'soccer' {
					border-color: $color-4;
				} @else {
					border-color: $color-primary;
				}

				@media (min-width: 992px) {
					width: 40px;
					height: 40px;
					line-height: 32px;
					border-width: 4px;
					left: 30px;
					font-size: 14px;
				}
			}
		}
			.alc-embeded-player__title {
				color: #fff;
				line-height: 1em;
				margin-bottom: .35em;

				@media (max-width: 991px) {
					font-size: $font-size-h5;
				}
			}
			.alc-embeded-player__date {
				color: $body-font-color;
				font-size: 9px;
				display: block;
				font-family: $font-family-accent;
				line-height: 1em;
				text-transform: uppercase;

				@media (min-width: 992px) {
					font-size: 10px;
				}
			}

// Centered
.alc-embeded-player--center {

	.alc-embeded-player__inner {
		left: 50%;
		bottom: auto;
		top: 50%;
		transform: translate(-50%, -50%);
		padding: 0 $card-horizontal-padding;
		text-align: center;
		width: 100%;

		@media (min-width: 576px) {
			width: 80%;
		}

		@media (min-width: 992px) {
			width: auto;
			padding-left: 0;
			padding-bottom: 0;
			width: 60%;
		}

		// Icon
		&::before {
			display: block;
			position: static;
			margin: 0 auto 35px auto;
		}
	}
}
	.alc-embeded-player--center-lg {

		.alc-embeded-player__inner {

			&::before {

				@media (min-width: 992px) {
					width: 60px;
					height: 60px;
					border-width: 6px;
					line-height: 48px;
					font-size: 20px;
				}
			}
		}
			.alc-embeded-player__title {
				margin-bottom: .5em;

				@media (min-width: 992px) {
					font-size: 28px;
				}
			}
	}
