/* Widget: Newsletter */
.widget-newsletter {

  .widget__content {

  }

  .widget-newsletter__subtitle {
    font-size: 13px;
    text-transform: uppercase;
    margin-bottom: 1.4em;
  }
  .widget-newsletter__desc {
    margin-bottom: 20px;

    p:last-child {
      margin-bottom: 0;
    }
  }

  .inline-form {
    padding: 8px 0;
  }
}
