/* Isotope Filter */
.isotope-filter {
	display: flex;
	padding: 0 2px;
	margin: 0 0 24px 0;
	flex-wrap: wrap;

	@media (min-width: 992px) {
		margin-bottom: 47px;
	}
}
	.isotope-filter__item {
		margin: 0 13px;


		@media (max-width: 991px) {
			margin: 0 15px 0 0;
		}

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}
		.isotope-filter__link {
			padding: 0 2px;
			color: $headings-color;
			font-style: normal;

			&:hover {
				color: $color-primary;
			}
		}
		.isotope-filter__link--active {
			color: $color-primary;
		}
