/* Widget: Banner */
.widget-banner {

  &.widget--sidebar {

    .widget__content {
      padding: 38px;
    }
  }
}
  .widget-banner__img {
    text-align: center;
    
    img {
      max-width: 100%;
      height: auto;
    }
  }
