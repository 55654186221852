/* Timeline
/* ------------------------- */

.timeline {
  list-style: none;
  padding: 0;
  margin: 2em 0 0 0;
  position: relative;
}
  .timeline__event {
    padding: 0 0 2.2em 46px;
    margin: 0;
    position: relative;

    // Vertical Line
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 9px;
      top: 0;
      bottom: 0;
      border-left: 1px solid $timeline-marker-line-color;
    }

    // Marker
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 19px;
      height: 19px;
      border-radius: 50%;
      background-color: $timeline-marker-color;
      border: 2px solid $timeline-marker-color;
      box-shadow: inset 0 0 0 5px $card-bg;
    }

    &:last-child {
      padding-bottom: 0;

      // Remove vertical line for last item
      &::before {
        display: none;
      }
    }
  }
    .timeline__title {
      font-size: 14px;
      line-height: 1.2em;
      text-transform: none;
    }
    .timeline__desc {

    }
