/*
// #Modal Login
// --------------------------------------------------
*/

.modal--login {

	.modal-header {

		background-image: url("../images/login_register_bg.jpg");
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: cover;
		border-radius: 0;

		@media (min-width: 992px) {
			height: 90px;
		}

		.close {
			width: 33px;
			height: 33px;
			padding: 0;
			line-height: 33px;
			border-radius: 50%;
			background-color: $color-2;
			color: #fff;
			opacity: 1;
			margin: 0;
			text-shadow: none;
			position: absolute;
			top: 0;
			right: 0;
			transform: translate(50%, -50%);
			transition: all 0.3s ease;

			span {
				margin-left: 2px;
			}

			&:hover {
				background-color: $color-primary-darken;
			}
		}
	}

	.modal-body {
		padding: 0;
	}


	.modal-account-holder {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
		.modal-account__item {
			flex-basis: 100%;
			padding: 20px;

			@media (min-width: 768px) {
				flex-basis: 50%;
				padding: 30px 20px;

				&:nth-child(2) {
					border-left: 1px solid $card-border-color;
				}
			}

			@media (min-width: 992px) {
				flex-basis: 50%;
				padding: 50px 38px;
			}
		}
		.modal-account__item--logo {
			background-image: url("../images/soccer/samples/login_register_bg.jpg");
			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-size: cover;
			color: #fff;
			position: relative;

			@media (max-width: 991px) {
				display: none;
			}

			a {
				color: $color-4;
				font-weight: bold;

				&:hover {
					color: #fff;
				}
			}
		}
			.modal-account__item-register-txt {
				position: absolute;
				left: 30px;
				right: 40px;
				top: 100%;
				transform: translateY(-100%) translateY(-30px);
			}
}

// Login Only
.modal--login-only {

	.modal-header {
		height: 0;
		padding: 0;
		background-image: none;
		border: none;
	}
}

.nav-tabs-login-wrapper {
	margin-left: -20px;
	margin-right: -20px;
	margin-bottom: -20px;
	margin-top: 20px;

	@media (min-width: 768px) {
		margin-left: -20px;
		margin-right: -20px;
		margin-bottom: -30px;
		margin-top: 30px;
	}

	@media (min-width: 992px) {
		margin-left: -38px;
		margin-right: -38px;
		margin-bottom: -50px;
		margin-top: 30px;
	}
}
	.nav-tabs--login {
		border-top: 1px solid $card-border-color;

		> li {

			> a {
				border: none !important;
				border-radius: 0 !important;
				@include font-accent(11px);
				color: rgba($headings-color, 0.4);
				padding-top: 15px;
				padding-bottom: 15px;

				&:hover {
					background-color: $card-bg;
					color: $color-primary;
				}
			}

			&.active {

				> a {
					color: $color-primary;
					background-color: $card-bg;

					&:hover {
						color: $color-primary;
						background-color: $card-bg;
					}
				}
			}

			& + li {
				border-left: 1px solid $card-border-color;
			}
		}
	}


// Register Form
.modal-form {

	h5 {
		margin-bottom: 1.7em;

		@media (min-width: 992px) {
			font-size: 18px;
		}
	}

	.form-group {
		margin-bottom: 15px;
	}
	.form-group--submit {
		margin: 30px 0;
		padding-top: 9px;
	}
	.modal-form--note {
		font-size: 14px;
		line-height: 22px;
	}

	.form-group--pass-reminder {
		font-size: 14px;
		overflow: hidden;

		label {
			font-size: 10px;
		}
		a {
			color: $body-font-color;

			&:hover {
				color: $color-primary-darken;
			}

			@media (min-width: 992px) {
				float: right;
			}
		}
	}
	.form-note {
		padding-top: 15px;
		padding-bottom: 15px;
	}
}
	.modal-form--social {
		padding-top: 4px;

		h6 {
			font-size: 10px;
			line-height: 1.2em;
			text-align: center;
		}
	}
