/* Widget: Tag Cloud */
.widget-tagcloud {

  .tagcloud {

    .btn {
      margin: 0 5px 9px 0;
    }
  }

  &.widget--sidebar {

    .widget__content {
      padding-bottom: 18px;
    }
  }
}
