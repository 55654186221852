/* Badges
/* ------------------------- */

// Colors
.badge-default {
	background-color: $btn-default-bg;
	color: #fff;
}

.badge-primary {
	background-color: $btn-primary-bg;
	color: #fff;
}
