/* Widget: Commentary */
.widget-commentary {
}
	.commentary {
		list-style: none;
		padding: 0;
		margin: 0;
	}
		.commentary__item {
			font-size: 11px;
			line-height: 17px;
			font-family: $font-family-accent;
			padding: 20px $card-horizontal-padding 20px 80px;
			position: relative;
			letter-spacing: -0.02em;

			& + & {
				border-top: 1px solid $card-border-color;
			}

			> strong {
				color: $headings-color;
				font-weight: 400;
			}

			// Icon
			&::before {
				content: "";
				display: block;
				position: absolute;
				left: 0;
				top: 0;
			}
		}
			// Time
			.commentary__time {
				color: $headings-color;
				display: block;
				position: absolute;
				left: 23px;
				top: 50%;
				width: 24px;
				transform: translateY(-50%);
			}
			.commentary__icon {
				display: block;
				position: absolute;
				left: 49px;
				top: 50%;
				transform: translateY(-50%);
			}
