/* Widget: Filter Size */
.widget_filter-size {

	.checkbox {
		font-style: normal;
	}

	.form-group {
		margin-bottom: 20px;
	}
}
