/*
Template Name: Alchemists
Template URI: https://themeforest.net/item/the-alchemists-sports-news-html-template/19106722
Author: Dan Fisher
Author URI: https://themeforest.net/user/dan_fisher
Description: Sports Club and News HTML Template
Version: 4.2.3
*/

/*
File Name:    style.css
Written by:   Dan Fisher - (https://themeforest.net/user/dan_fisher)
Description:  General styling for template (including Header, Footer, Sidebar)
*/

/* Table of Content
==================================================

	#Common

	#Base
		- Header
		- Header Navigation
		- Hero Unit
		- Pushy Panel
		- Page Header
		- Content
		- Footer

	#Blog
		- Blog
		- Posts
		- Single Post
		- Post Author
		- Post Meta
		- Related Posts
		- Post Comments
		- Blog Filter
		- Blog Load More

	#Widgets
		- List
		- Instagram
		- Contact Info
		- Social
		- Tag Cloud
		- Banner
		- Tabbed
		- Newsletter
		- Match Preview
		- Comments
		- Twitter
		- Featured
		- Standings
		- Player
		- Game Result
		- Poll
		- Results
		- Awards
		- Games History
		- Newslog
		- Team Leaders
		- Team Stats
		- Filter Color
		- Filter Size
		- Filter Price
		- Products

	#Team
		- Team Filter
		- Team Roster
		- Team Standings
		- Team Glossary
		- Team Results
		- Team Schedule

	#Player
		- Player Heading
		- Player Gamelog
		- Player Season Average

	#Shop
		- Checkout Review Order
		- Checkout Payment
		- Account Navigation
		- Shop Table
		- Shop Banner
		- Shop Filter
		- Products
		- Filter Color
		- Product Tabs

	#Partials
		- Info Block
		- Social Counter
		- Social Links
		- Match Preview
		- Game Result
		- Main News Banner
		- Featured Carousel
		- Content Filter
		- Gallery
		- Album
		- Modal Login
*/
