/*
// #Hero Unit
// --------------------------------------------------
*/

.hero-unit {
	background: #27313b url("../images/header_bg.jpg") 50% 0 no-repeat;
	background-size: cover;
}
	.hero-unit__content--left-center {
		text-align: center;

		@media (min-width: 992px) {
			width: 50%;
		}
	}
		.hero-unit__container {
			height: 400px;
			position: relative;

			@media (min-width: 1199px) {
				height: 640px;
			}
		}
		.hero-unit__content {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			right: 0;
			text-align: center;
		}
			// Title
			.hero-unit__title {
				color: #fff;
				margin-bottom: 0.75em;
				font-size: 30px;

				@media (min-width: 1199px) {
					font-size: 64px;
					margin-bottom: 0.25em;
				}
			}

			// Subtitle
			.hero-unit__subtitle {
				color: #fff;
				margin-bottom: 0.25em;
				font-size: 12px;

				@media (min-width: 992px) {
					font-size: 18px;
				}
			}

			// Description
			.hero-unit__desc {
				margin-bottom: 3em;
				width: 70%;
				margin-left: auto;
				margin-right: auto;

				@media (min-width: 768px) {
					width: 60%;
				}

				@media (min-width: 992px) {
					width: 80%;
				}

				@media (min-width: 1199px) {
					width: 70%;
				}
			}

			// Button
			.hero-unit__btn {

			}

			// Decoration (stars)
			.hero-unit__decor {
				display: block;
				color: #ffdc11;
				margin-bottom: 5px;

				@media (min-width: 992px) {
					margin-bottom: 11px;
				}

				.fa,
				.fab,
				.fas {
					margin: 0 6px;
				}
			}

			// Images
			.hero-unit__img {
				position: absolute;
				right: 0;
				bottom: 0;
				max-width: 50%;

				img {
					max-height: 100%;
				}

				// Hide image on tablets and mobile devices
				@media (max-width: 991px) {
					display: none;
				}
			}
