/* Icon */
.alc-icon {
	display: inline-block;
	color: $color-white;
	background-color: $card-bg;
	position: relative;

	i,
	svg {
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}


// Shapes
.alc-icon--circle {
	border-radius: 50%;
}

// Size
.alc-icon--xl {
	width: 80px;
	height: 80px;

	.df-icon {
		transform: translate(-50%, -50%) scale(1.75);
	}
}
.alc-icon--sm {
	width: 40px;
	height: 40px;
}
.alc-icon--xs {
	width: 20px;
	height: 20px;

	i {
		font-size: 8px;
	}
}

// Border Style
.alc-icon--outline {
	background-color: transparent;
	border: 1px solid $color-primary;
}
.alc-icon--outline-md {
	border-width: 4px;

	&.alc-icon--sm {
		line-height: 32px;
	}

	&.alc-icon--xs {
		border-width: 2px;
	}
}
.alc-icon--outline-xl {
	border-width: 8px;
}

// Colors
.alc-icon--disabled {
	border-color: #fff;
	color: #fff;
	opacity: .1;
}
