/* Widget: Social */
.widget-social {

}

// Condensed
.widget-social--condensed {
	display: flex;
	justify-content: space-between;
	margin-left: -5px;
	margin-right: -5px;
	overflow: hidden;

	.btn-social-counter {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		padding: 0;
		text-align: center;
		margin-left: 5px;
		margin-right: 5px;
		width: 33.3%;
		max-width: 33.3%;

		& + .btn-social-counter {
			margin-top: 0;
		}
	}
		.btn-social-counter__icon {
			position: relative;
			width: 100%;
			padding: 35px 0 10px 0;
			background-color: transparent !important;
		}
		.btn-social-counter__title {
			font-size: 10px;
			padding: 0 12px 10px 12px;
		}
		.btn-social-counter__add-icon {
			position: relative;
			right: auto;
			top: 0;
			margin-top: 0;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 10px;
		}
		.btn-social-counter__count {
			order: 9;
			padding: 10px 5px;
		}
}

// Grid
.widget-social--grid {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	overflow: hidden;
	margin-bottom: 5px;

	.btn-social-counter {
		padding: 0;
		text-align: center;
		flex-basis: calc(50% - 5px);
		max-width: calc(50% - 5px);
		margin-bottom: 10px;
		text-align: left;
		padding-left: 46px;
		padding-top: 20px;
		padding-right: 38px;

		& + .btn-social-counter {
			margin-top: 0;
		}

		.btn-social-counter__icon {
			background-color: transparent;
			width: 46px;
			text-indent: 8px;
		}
		.btn-social-counter__title {
			font-size: 12px;
			line-height: 1em;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		.btn-social-counter__count {
			font-size: 10px;
			line-height: 1em;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		.btn-social-counter__add-icon {
			border: none;
			width: 34px;
			top: 0;
			bottom: 0;
			right: 0;
			height: auto;
			margin: 0;
			border-radius: 0;
			background-color: rgba(255,255,255,.1);
		}
	}
}

// Buttons
.widget-social--buttons {
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
	margin: 0 -8px;

	.btn-social-counter {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		padding: 0;
		text-align: center;
		width: calc(33.3% - 16px);
		max-width: calc(33.3% - 16px);
		border: none;
		margin: 0 8px 16px 8px;

		& + .btn-social-counter {
			margin-top: 0;
		}
	}
		.btn-social-counter__icon {
			position: relative;
			width: 100%;
			padding: 45px 0 0 0;
			background-color: transparent !important;
		}
		.btn-social-counter__title {
			display: none;
		}
		.btn-social-counter__add-icon {
			width: 18px;
			height: 18px;
			right: 8px;
			top: 8px;
			margin-top: 0;
		}
		.btn-social-counter__count {
			order: 9;
			padding: 4px 5px 14px 5px;
		}
}
