// Forms

@mixin form-control-focus() {
  &:focus {
    color: $input-color-focus;
    background-color: $input-bg-focus;
    border-color: $input-border-focus;
    box-shadow: $input-box-shadow-focus;
  }
}

// Placeholder text
@mixin placeholder($color: $input-color-placeholder) {
  // Firefox
  &::-moz-placeholder {
    color: $color;
    opacity: 1;
  }
  &:-ms-input-placeholder { color: $color; } // Internet Explorer 10+
  &::-webkit-input-placeholder  { color: $color; } // Safari and Chrome
}


// Form validation states

@mixin form-control-validation($text-color: #555, $border-color: #ccc, $background-color: #f5f5f5) {
  // Color the label and help text
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  &.radio label,
  &.checkbox label,
  &.radio-inline label,
  &.checkbox-inline label  {
    // color: $text-color;
    color: $input-label-color;
  }
  // Set the border and box shadow on specific inputs to match
  .form-control {
    border-color: $border-color;
    box-shadow: none;
    background-position: center right 0.9em;
    background-repeat: no-repeat;
    background-size: 20px 16px;
    padding-right: 2.2em;
    &:focus {
      border-color: $border-color;
      box-shadow: none;
    }
  }
  // Set validation states also for addons
  .input-group-addon {
    color: $text-color;
    border-color: $border-color;
    background-color: $background-color;
  }
  // Optional feedback icon
  .form-control-feedback {
    color: $text-color;
  }
}
